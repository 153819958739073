
import React from 'react'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CCol,
  CFormLabel,
  CFormInput,
  CImage,
  CButton,
  CInputGroup,
  CInputGroupText,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem
} from '@coreui/react-pro'
import FormImageThumbnail from './FormImageThumbnail'

// icons
import AddMoreIconWhite from '../../../assets/icons/AddMoreIconWhite.png'
import AddMoreIconGrey from '../../../assets/icons/AddMoreIconGrey.png'
import TrashIcon from '../../../assets/icons/TrashIcon.png'

// styling
import { styleButtonVars2 } from 'src/components/custom-styles/buttonStyles'
import { styleAddImageVariant } from 'src/components/custom-styles/containerStyle'

const FormProductVariant = ({
  formProduct,
  editPage,
  variantType,
  variantIndex,
  handleAddVariantOptions,
  handleRemoveVariantOptions,
  values,
  errors,
  handleTextVariantOptions,
  handleImageVariantOptions,
  handlePriceVariantOptions,
  handleRemoveImageVariantOptions,
  setOpenFilePreview,
  setShowConfirmDelete,
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    <>
      <CRow>
        <CCol lg={12} >
          <div className='mb-3 d-grid gap-2 d-md-flex justify-content-between align-items-center'>
            <CFormLabel>
              Product Variant Options
            </CFormLabel>
            <CButton
              onClick={() => handleAddVariantOptions(variantType, variantIndex)}
              style={styleButtonVars2}
            >
              <CImage fluid src={AddMoreIconWhite} className='me-2' />
              {variantType?.options?.length > 0 ? 'Add More' : 'Add'}
            </CButton>
          </div>
        </CCol>
      </CRow>
      {variantType?.options?.length > 0 && (
        <CAccordion>
          {variantType?.options?.map((option, index) => {
            return (
              <CAccordionItem itemKey={index} key={index} >
                <CAccordionHeader>
                  <b>Product Variant Option {index + 1}</b>
                </CAccordionHeader>
                <CAccordionBody>
                  <CRow>
                    <CCol lg={6}>
                      <CFormLabel>
                        Variant Option Name
                      </CFormLabel>
                      <CInputGroup className='mb-2'>
                        <CFormInput
                          placeholder='Fill in variant option name...'
                          onChange={({ target: { value } }) => handleTextVariantOptions(value, variantIndex, option, index)}
                          value={option.option_name}
                          feedbackInvalid={errors.option_name}
                          invalid={errors.option_name}
                        />
                        <CInputGroupText
                          onClick={() => {
                            option.option_id ?
                              setShowConfirmDelete({ show: true, item: option, type: 'product-variant-option' })
                              : handleRemoveVariantOptions(variantIndex, option, index)
                          }}
                        >
                          <CImage fluid src={TrashIcon} />
                        </CInputGroupText>
                      </CInputGroup>
                      <CFormLabel>
                        Variant Option Price
                      </CFormLabel>
                      <CInputGroup>
                        <CInputGroupText>{currency?.code}</CInputGroupText>
                        <CFormInput
                          placeholder='Fill in variant option price...'
                          onChange={({ target: { value } }) => handlePriceVariantOptions(value, variantIndex, option, index)}
                          value={option.option_price}
                          feedbackInvalid={errors.option_price}
                          invalid={errors.option_price}
                        />
                      </CInputGroup>
                    </CCol>
                    <CCol lg={6}>
                      <div className='d-grid gap-2 d-md-flex align-items-center'>
                        <CFormLabel >
                          Variant Option {index + 1} Image
                        </CFormLabel>
                        <p
                          style={{
                            color: '#9E9E9E',
                            fontSize: '16px',
                            marginBottom: '.5rem'
                          }}
                        >
                          (Optional)
                        </p>
                      </div>
                      {option?.option_images?.length > 0 ? (
                        <FormImageThumbnail
                          variantTypeIndex={variantIndex}
                          variantOption={option}
                          variantOptionIndex={index}
                          formProduct={formProduct}
                          editPage={editPage}
                          colSize='12'
                          productImages={option?.option_images}
                          handleRemoveImage={handleRemoveImageVariantOptions}
                          handleChangeImage={handleImageVariantOptions}
                          setOpenFilePreview={setOpenFilePreview}
                        />
                      ) : (
                        <>
                          <CFormInput
                            id="upload_single_image_variant_product"
                            className="position-absolute invisible"
                            type="file"
                            onChange={(e) => handleImageVariantOptions(e, variantIndex, option, index)}
                            multiple={false}
                            accept='.jpeg, .png, .gif'
                          />
                          <label className="mb-3" htmlFor="upload_single_image_variant_product">
                            <CImage
                              fluid
                              src={AddMoreIconGrey}
                              style={styleAddImageVariant}
                            />
                          </label>
                        </>
                      )}
                    </CCol>
                  </CRow>
                </CAccordionBody>
              </CAccordionItem>
            )
          })}
        </CAccordion>
      )}
    </>
  )
}

export default FormProductVariant
