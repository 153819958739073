import React, { useEffect, useState } from 'react'
import { useFormik } from "formik"
import * as Yup from "yup"
import { useNavigate } from 'react-router-dom'

// components
import AppCard from 'src/components/AppCard'
import AppForm from 'src/components/form/add-product/AppForm'
import AppModalFilePreview from 'src/components/modal/AppModalFilePreview'
import ActionButtonFormFirst from 'src/components/form/general-input-form/ActionButtonFormFirst'

// helpers
import Axios from 'src/helpers/axios'
import { singleOptionsSelect } from 'src/helpers/format-options'
import { capitalizeEachWord } from 'src/helpers/text-format'
import { getArrayCombinationVariantID } from 'src/helpers/format-api-fetch-data'
import getCroppedImg from 'src/helpers/crop-image'

const AddProduct = () => {
  const navigate = useNavigate()

  const [categoryOptions, setCategoryOptions] = useState([])
  const [subCategoryOptions, setSubCategoryOptions] = useState([])
  const [brandOptions, setBrandOptions] = useState([])
  const [shippingOptions, setShippingOptions] = useState([])
  const [discountTypeOptions, setDiscountTypeOptions] = useState([{
    label: "Please select discount type...",
    value: ""
  }, {
    label: "Fixed Amount",
    value: "fixed-amount"
  }, {
    label: "Percentage",
    value: "percentage"
  }])
  const [weightUnitOptions, setWeightUnitOptions] = useState([{
    label: "gr",
    value: "grams"
  }
    // , {
    //   label: "kg",
    //   value: "kilograms"
    // }
  ])

  const [genderOptions, setGenderOptions] = useState([{
    label: "Please select target gender...",
    value: ""
  }, {
    label: "Male",
    value: "male"
  }, {
    label: "Female",
    value: "female"
  }, {
    label: "Unisex",
    value: "unisex"
  }])

  const [openFilePreview, setOpenFilePreview] = useState(false)
  const [isLoadingDraft, setIsLoadingDraft] = useState(false)
  const [isLoadingPublish, setIsLoadingPublish] = useState(false)
  const [openFormProductVariant, setOpenFormProductVariant] = useState(false)
  const [openDisplaySizeGuide, setOpenDisplaySizeGuide] = useState(false)
  const [openFormProductCombination, setOpenFormProductCombination] = useState(false)
  const [inputVariant, setInputVariant] = useState('')

  // state for validation empty field
  const [isProductInfoEmpty, setIsProductInfoEmpty] = useState(false)
  const [isProductVariantEmpty, setIsProductVariantEmpty] = useState(false)
  const [isProductCombinationEmpty, setIsProductCombinationEmpty] = useState(false)
  const [isProductCombinationDiscountInvalid, setIsProductCombinationDiscountInvalid] = useState(false)

  const formik = useFormik({
    initialValues: {
      product_name: '',
      category_id: '',
      subcategory_id: '',
      brand_id: '',
      product_desc: '',
      product_quantity: '',
      product_price: '',
      product_discount_type: '',
      product_discount_value: '',
      product_weight: '',
      product_weight_unit: 'grams',
      product_shipping: [],
      product_images: [],
      product_info: [{
        info_title: '',
        info_desc: ''
      }],
      product_images: [],
      product_variants: [],
      product_combinations: [],
      gender: "",
    },
    validationSchema: Yup.object().shape({
      product_name: Yup.string()
        .required("Please enter product name."),
      category_id: Yup.string()
        .required("Please select category option."),
      subcategory_id: Yup.string()
        .required("Please select sub category option."),
      brand_id: Yup.string()
        .required("Please select brand option."),
      product_desc: Yup.string()
        .max(200, "Max 200 characters allowed.")
        .required("Please enter product description."),
      product_quantity: Yup.string()
        .when('product_combinations', {
          is: value => value && value.length == 0,
          then: () => Yup.number().min(1, "Please enter a valid product quantity").required("Please enter product quantity."),
        }),
      product_price: Yup.number().min(1, "Please enter a valid product price").required("Please enter product price."),
      product_weight: Yup.string()
        .when('product_combinations', {
          is: value => value && value.length == 0,
          then: () => Yup.number().min(1, "Please enter a valid product weight").required("Please enter product weight."),
        }),
      // product_shipping: Yup.array()
      //   .of(Yup.object())
      //   .min(1, 'Please input product shipping method at least 1.'),
      product_images: Yup.array()
        .of(Yup.mixed())
        .min(1, 'Please input product images at least 1.'),
      product_info: Yup.array()
        .of(Yup.object())
        .min(1, 'Please input product information at least 1.'),
      gender: Yup.string(),
      product_discount_value: Yup.string()
        .when('product_discount_type', {
          is: value => value === "fixed-amount",
          then: () => Yup.number().lessThan(formik?.values?.product_price, "Product discount value must be less than  product price"),
        })
        .when('product_discount_type', {
          is: value => value === "percentage",
          then: () => Yup.number().lessThan(100, "Product discount value must be less than  100%"),
        }),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setSubmitting }) => {
      const isAllInputValid = handleInputValidation(values);
      if (isAllInputValid) {
        await handlePublish(values, false)
        setSubmitting(false)
        navigate('/products/list')
        window.location.reload()
      }
    },
  });

  useEffect(() => {
    fetchAllCategories()
    fetchAllBrands()
    fetchAllShippingMethod()
  }, []);

  useEffect(() => {
    if (formik.values.category_id !== '') {
      fetchAllSubCategoriesByCategoryId(formik.values.category_id)
    }
  }, [formik.values.category_id]);

  // API handler function
  const fetchAllCategories = () => {
    Axios({
      method: "get",
      url: "/api/ecommerce/v1/categories/all",
    })
      .then(({ data }) => {
        setCategoryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllSubCategoriesByCategoryId = (id) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/categories/sub/category/${id}`,
    })
      .then(({ data }) => {
        setSubCategoryOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllBrands = () => {
    Axios({
      method: "get",
      url: "/api/ecommerce/v1/brands/products/all",
    })
      .then(({ data }) => {
        setBrandOptions(singleOptionsSelect(data))
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllShippingMethod = () => {
    Axios({
      method: "get",
      url: "/api/ecommerce/v1/shippingmethods/",
    })
      .then(({ data }) => {
        setShippingOptions(data)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createProduct = async (payload) => {
    try {
      const response = await Axios.post(`/api/ecommerce/v1/products/create`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const createProductImages = async (id, payload) => {
    try {
      const response = await Axios.post(`/api/ecommerce/v1/products/upload/image/${id}`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const createProductInformation = async (payload) => {
    try {
      const response = await Axios.post(`/api/ecommerce/v1/productinformation/create`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const createProductShipping = async (payload) => {
    try {
      const response = await Axios.post(`/api/ecommerce/v1/productshipping/create`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const createProductVariant = async (id, payload) => {
    try {
      const response = await Axios.post(`/api/ecommerce/v1/products/variant/create/${id}`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const createProductVariantOptions = async (id, payload) => {
    try {
      const response = await Axios.post(`/api/ecommerce/v1/products/variant/option/create/${id}`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  const createProductCombination = async (id, payload) => {
    try {
      const response = await Axios.post(`/api/ecommerce/v1/products/combination/create/${id}`, payload)
      const { data } = response
      return data;
    } catch (error) {
      console.error(error)
    }
  }

  // additional input validation
  const handleInputValidation = (values) => {
    // empty field validation
    let isProductInfoFieldEmpty = false;
    let isProductVariantFieldEmpty = false;
    let isProductCombinationFieldEmpty = false;
    let isProductCombinationDiscountFieldInvalid = false;

    // check field product information
    if (values.product_info.length > 0) {
      for (let i = 0; i < values.product_info.length; i++) {
        if (!values.product_info[i].info_title || values.product_info[i].info_title == '') {
          isProductInfoFieldEmpty = true
        }
        if (!values.product_info[i].info_desc || values.product_info[i].info_desc == '') {
          isProductInfoFieldEmpty = true
        }
      }
    }

    // check field product variant
    if (values.product_variants.length > 0) {
      for (let i = 0; i < values.product_variants.length; i++) {
        if (!values.product_variants[i].name || values.product_variants[i].name == '') {
          isProductVariantFieldEmpty = true
        }
        for (let j = 0; j < values.product_variants[i].options.length; j++) {
          if (!values.product_variants[i].options[j].option_name || values.product_variants[i].options[j].option_name == '') {
            isProductVariantFieldEmpty = true
          }
          if (!values.product_variants[i].options[j].option_price || !parseInt(values.product_variants[i].options[j].option_price) || values.product_variants[i].options[j].option_price == '') {
            isProductVariantFieldEmpty = true
          }
        }
      }
    }

    // check field product combination
    if (values.product_combinations.length > 0) {
      for (let i = 0; i < values.product_combinations.length; i++) {

        const combination_price = values.product_combinations[i].combinations.map(combination => parseInt(combination?.variant_price)).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        const combination_discount_condition1 = values.product_combinations[i].discount_type === "fixed-amount" && parseInt(values.product_combinations[i].discount_value) >= combination_price
        const combination_discount_condition2 = values.product_combinations[i].discount_type === "percentage" && parseInt(values.product_combinations[i].discount_value) >= 100

        if (!values.product_combinations[i].quantity || values.product_combinations[i].quantity == '') {
          isProductCombinationFieldEmpty = true
        }
        if (!values.product_combinations[i].weight || values.product_combinations[i].weight == '') {
          isProductCombinationFieldEmpty = true
        }
        if (combination_discount_condition1 || combination_discount_condition2) {
          isProductCombinationDiscountFieldInvalid = true
        }
        for (let j = 0; j < values.product_combinations[i].combinations.length; j++) {
          if (!values.product_combinations[i].combinations[j].variant_type || values.product_combinations[i].combinations[j].variant_type == '') {
            isProductCombinationFieldEmpty = true
          }
          if (!values.product_combinations[i].combinations[j].variant_option || values.product_combinations[i].combinations[j].variant_option == '') {
            isProductCombinationFieldEmpty = true
          }
        }
      }
    }

    setIsProductInfoEmpty(isProductInfoFieldEmpty);
    setIsProductVariantEmpty(isProductVariantFieldEmpty);
    setIsProductCombinationEmpty(isProductCombinationFieldEmpty);
    setIsProductCombinationDiscountInvalid(isProductCombinationDiscountFieldInvalid);

    if (
      isProductInfoFieldEmpty !== true &&
      isProductVariantFieldEmpty !== true &&
      isProductCombinationFieldEmpty !== true &&
      isProductCombinationDiscountFieldInvalid !== true
    ) {
      return true;
    } else {
      return false;
    }
  };

  // handler function for input value
  const handleInputProduct = (values, isDraft) => {
    const objectPayload = {}
    objectPayload.name = values.product_name
    objectPayload.description = values.product_desc
    objectPayload.is_active = true
    objectPayload.is_draft = isDraft
    objectPayload.qty = values.product_combinations.length > 0 ? 0 : values.product_quantity !== '' ? parseInt(values.product_quantity) : 0
    objectPayload.price = parseInt(values.product_price) || 0
    objectPayload.weight = values.product_combinations.length > 0 ? 0 : values.product_weight !== '' ? parseInt(values.product_weight) : 0
    objectPayload.weight_unit = values.product_weight_unit
    objectPayload.sub_category_id = parseInt(values.subcategory_id)
    objectPayload.brand_id = parseInt(values.brand_id)
    objectPayload.status = isDraft ? 'draft' : 'active'
    objectPayload.stock_threshold = parseInt('5')
    objectPayload.discount_type = values.product_discount_type
    objectPayload.discount_value = parseInt(values.product_discount_value || '0')
    objectPayload.gender = values.gender

    return objectPayload
  }

  const handleInputProductInformation = (id, values) => {
    const objectPayload = {}

    objectPayload.product_id = id
    objectPayload.title = values.info_title
    objectPayload.description = values.info_desc

    return objectPayload
  }

  const handleInputProductShipping = (id, arrayValues) => {
    const objectPayload = []

    for (let i = 0; i < arrayValues.length; i++) {
      objectPayload.push({
        product_id: id,
        shipping_id: arrayValues[i].value
      })
    }

    return objectPayload
  }

  const handleInputProductVariant = (values) => {
    const objectPayload = {}

    objectPayload.name = values.name

    return objectPayload
  }

  const handleInputProductVariantOption = (values) => {
    const formData = new FormData()

    formData.append('name', values.option_name)
    formData.append('price', values.option_price)
    if (values.option_images && values.option_images[0] && values.option_images[0] instanceof File) {
      formData.append('file', values.option_images[0])
    }

    return formData
  }

  const handleInputProductCombination = async (productId, values) => {
    const formData = new FormData()

    formData.append('qty', values.quantity)
    formData.append('weight', values.weight)
    formData.append('weight_unit', values.weight_unit)
    formData.append('discount_type', values.discount_type)
    formData.append('discount_value', values.discount_value)
    if (values.images && values.images[0] && values.images[0] instanceof File) {
      formData.append('file', values.images[0])
    }
    if (values.combinations && values.combinations.length > 0) {
      let arrayIdCombination = await getArrayCombinationVariantID(productId, values.combinations)
      arrayIdCombination = JSON.stringify(arrayIdCombination)
      formData.append('combinations', arrayIdCombination)
    }

    return formData
  }

  const handleInputProductImage = (values) => {
    const formData = new FormData()

    if (values && values instanceof File) {
      formData.append('file', values)
    }

    return formData
  }

  const handlePublish = async (values, isDraft) => {
    if (isDraft) {
      setIsLoadingDraft(true)
    } else {
      setIsLoadingPublish(true)
    }

    // input product
    const productPayload = handleInputProduct(values, isDraft)

    // create product
    const product = await createProduct(productPayload)

    // product image
    if (values.product_images.length > 0) {
      for (let i = 0; i < values.product_images.length; i++) {
        // input product image
        const productImagePayload = handleInputProductImage(values.product_images[i])
        // create product image
        await createProductImages(product.id, productImagePayload)
      }
    }

    // create product information
    if (values.product_info.length > 0) {
      for (let i = 0; i < values.product_info.length; i++) {
        // input product information
        const productInformationPayload = handleInputProductInformation(product.id, values.product_info[i])
        // create product information
        await createProductInformation(productInformationPayload)
      }
    }

    // create product shipping
    if (values.product_shipping.length > 0) {
      // input product shipping
      const productShippingPayload = handleInputProductShipping(product.id, values.product_shipping)
      // create product shipping
      await createProductShipping(productShippingPayload)
    }

    // create product variant
    if (values.product_variants.length > 0) {
      for (let i = 0; i < values.product_variants.length; i++) {
        // input product variant
        const productVariantPayload = handleInputProductVariant(values.product_variants[i])
        // create product variant
        const productVariantType = await createProductVariant(product.id, productVariantPayload)

        // create variant options
        if (values.product_variants[i].options.length > 0) {
          for (let j = 0; j < values.product_variants[i].options.length; j++) {
            // input product variant options
            const productVariantOptionsPayload = handleInputProductVariantOption(values.product_variants[i].options[j])
            // create product variant options
            await createProductVariantOptions(productVariantType.id, productVariantOptionsPayload)
          }
        }
      }
    }

    // create product combinations
    if (values.product_combinations.length > 0) {
      for (let i = 0; i < values.product_combinations.length; i++) {
        // input product combination
        const productCombinationPayload = await handleInputProductCombination(product.id, values.product_combinations[i])
        // create product combination
        await createProductCombination(product.id, productCombinationPayload)
      }
    }

    if (isDraft) {
      setIsLoadingDraft(false)
      navigate('/products/list')
      window.location.reload()
    } else {
      setIsLoadingPublish(false)
    }
  }

  const handleRemoveImage = (file) => {
    formik.setValues((values) => ({
      ...values,
      product_images: values.product_images.filter(image => image !== file)
    }), false)
  }

  const handleImagesOnDrop = (files) => {
    const attachments = []
    files.forEach(file => {
      if (file && typeof file !== 'undefined') {
        attachments.push(file)
      }
    })
    formik.setValues((values) => ({
      ...values,
      product_images: attachments
    }), false)
  }


  const handleCropImage = async (file, originalImage, croppedAreaPixels, rotation) => {
    try {
      const croppedImage = await getCroppedImg(
        originalImage,
        file,
        croppedAreaPixels,
        rotation,
      )
      formik.setValues((values) => ({
        ...values,
        product_images: values.product_images.map((image, i) => {
          if (image === originalImage) {
            return croppedImage;
          } else {
            return image
          }
        })
      }), false)
    } catch (e) {
      console.error(e)
    }
  }

  const handleImagesAttachment = (e) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    files.forEach(file => {
      if (file && typeof file !== 'undefined') {
        attachments.push(file)
      }
    })
    formik.setValues((values) => ({
      ...values,
      product_images: attachments
    }), false)
  }

  const handleProductShippingList = (value) => {
    formik.setValues((values) => ({
      ...values,
      product_shipping: value
    }), false)
  }

  // handler function product information
  const handleAddProductInformation = () => {
    formik.setValues((values) => ({
      ...values,
      product_info: [
        ...values.product_info,
        ...[{
          info_title: '',
          info_desc: ''
        }]
      ]
    }), false)
  }

  const handleRemoveProductInformation = (index) => {
    formik.setValues((values) => ({
      ...values,
      product_info: values.product_info.filter((item, itemIndex) => itemIndex !== index)
    }), false)
  }

  const handleProductInfoTitle = (e, informationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayInformation = values.product_info.map((item, itemIndex) => {
        if (itemIndex == informationIndex) {
          return {
            ...item,
            info_title: value
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_info: [
          ...newArrayInformation,
        ]
      }
    }, false);
  }

  const handleProductInfoDesc = (e, informationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayInformation = values.product_info.map((item, itemIndex) => {
        if (itemIndex == informationIndex) {
          return {
            ...item,
            info_desc: value
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_info: [
          ...newArrayInformation,
        ]
      }
    }, false);
  }

  // handler function product variant
  const handleInputVariantType = (e) => {
    if (e.key === 'Enter' && inputVariant !== '') {
      const product_variant_names = formik?.values?.product_variants?.map(item => item?.name?.toLowerCase())
      if (!product_variant_names?.includes(inputVariant?.toLowerCase()?.trim())) {
        formik.setValues((values) => ({
          ...values,
          product_variants: [
            ...values.product_variants,
            ...[{
              name: capitalizeEachWord(inputVariant),
              options: [{
                option_name: '',
                option_price: '',
                option_images: []
              }]
            }]
          ],
          product_combinations: [
            ...values?.product_combinations.map(product_combination => ({
              ...product_combination,
              combinations: [
                ...product_combination?.combinations,
                {
                  variant_type: capitalizeEachWord(inputVariant),
                  variant_option: '',
                }
              ]
            })),
          ]
        }), false)
        setInputVariant('')
      }
    }
    if (e.key === 'Escape') {
      setInputVariant('')
    }
  }

  const handleRemoveInputVariantType = (variant) => {
    formik.setValues((values) => ({
      ...values,
      product_variants: values.product_variants.filter((item) => item !== variant),
      product_combinations: [
        ...values?.product_combinations.map(product_combination => {
          return ({
            ...product_combination,
            combinations: product_combination?.combinations?.filter((item) => item?.variant_type !== variant?.name)
          })
        }),
      ]
    }), false)
  }

  const handleAddVariantOptions = (variant, variantIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (item.name == variant.name && itemIndex == variantIndex) {
          return {
            ...item,
            options: [
              ...item.options,
              ...[{
                option_name: '',
                option_price: '',
                option_images: []
              }]
            ]
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleRemoveVariantOptions = (variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.filter((element, elementIndex) => elementIndex !== optionIndex)
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleTextVariantOptions = (name, variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_name: name
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handlePriceVariantOptions = (price, variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_price: price
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleImageVariantOptions = (e, variantIndex, option, optionIndex) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
    }

    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_images: attachments
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  const handleRemoveImageVariantOptions = (image, imageIndex, variantIndex, option, optionIndex) => {
    formik.setValues((values) => {
      const newArrayVariantOptions = values.product_variants.map((item, itemIndex) => {
        if (itemIndex == variantIndex) {
          return {
            ...item,
            options: item.options.map((element, elementIndex) => {
              if (elementIndex == optionIndex) {
                return {
                  ...element,
                  option_images: element.option_images.filter((optionImage, optionImageIndex) => optionImage !== image)
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_variants: [
          ...newArrayVariantOptions,
        ]
      }
    }, false);
  }

  // handler function product combination
  const handleAddProductCombination = () => {
    formik.setValues((values) => ({
      ...values,
      product_combinations: [
        ...values.product_combinations,
        ...[{
          name: `Product Combination ${values.product_combinations.length + 1}`,
          quantity: '',
          weight: '',
          weight_unit: 'grams',
          discount_type: '',
          discount_value: '',
          images: [],
          combinations: [
            ...values?.product_variants?.map((variant) => ({
              ...variant,
              variant_type: variant?.name,
              variant_option: '',
            }))
          ]
        }]
      ]
    }), false)
  }

  const handleRemoveProductCombination = (index) => {
    formik.setValues((values) => ({
      ...values,
      product_combinations: values.product_combinations.filter((item, itemIndex) => itemIndex !== index)
    }), false)
  }

  const handleAddCombinationList = (combinationIndex) => {
    formik.setValues((values) => {
      const newArrayProductCombination = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            combinations: [
              ...item.combinations,
              ...[{
                variant_type: '',
                variant_option: '',
              }]
            ]
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayProductCombination,
        ]
      }
    }, false);
  }

  const handleRemoveCombinationList = (listIndex, combinationIndex) => {
    formik.setValues((values) => {
      const newArrayProductCombination = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            combinations: item.combinations.filter((element, elementIndex) => elementIndex !== listIndex)
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayProductCombination,
        ]
      }
    }, false);
  }

  const handleVariantTypeProductCombinations = (value, listIndex, combinationIndex) => {
    // const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            combinations: item.combinations.map((element, elementIndex) => {
              if (elementIndex == listIndex) {
                return {
                  ...element,
                  variant_type: value[0].value
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleVariantOptionProductCombinations = (value, listIndex, combinationIndex) => {
    // const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            combinations: item.combinations?.map((element, elementIndex) => {
              if (elementIndex == listIndex) {
                return {
                  ...element,
                  variant_option: value?.[0]?.value,
                  variant_price: value?.[0]?.price,
                }
              } else {
                return element
              }
            })
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleStockProductCombinations = (e, combinationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            quantity: value
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleWeightProductCombinations = (e, combinationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            weight: value
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleWeightUnitProductCombinations = (e, combinationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            weight_unit: value
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleDiscountTypeProductCombinations = (e, combinationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            discount_type: value,
            discount_value: ''
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleDiscountValueProductCombinations = (e, combinationIndex) => {
    const value = e.target.value
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            discount_value: value
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleImageProductCombinations = (e, combinationIndex) => {
    e.preventDefault()
    const files = e.target.files
    const attachments = []
    if (files[0] && typeof files[0] !== 'undefined') {
      attachments.push(files[0])
    }

    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            images: attachments
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  const handleRemoveImageProductCombinations = (image, imageIndex, variantTypeIndex, variantOption, variantOptionIndex, combinationIndex) => {
    formik.setValues((values) => {
      const newArrayCombinations = values.product_combinations.map((item, itemIndex) => {
        if (itemIndex == combinationIndex) {
          return {
            ...item,
            images: item.images.filter((combinationImage, combinationImageIndex) => combinationImageIndex !== imageIndex)
          };
        } else {
          return item;
        }
      });

      return {
        ...values,
        product_combinations: [
          ...newArrayCombinations,
        ]
      }
    }, false);
  }

  return (
    <>
      {openFilePreview && (
        <AppModalFilePreview
          setOpenFilePreview={setOpenFilePreview}
          file={openFilePreview}
        />
      )}
      <AppCard
        className='mb-4'
        headerTitle={<h4>Add Product</h4>}
        bodyContent={
          <AppForm
            values={formik.values}
            handleChange={formik.handleChange}
            errors={formik.errors}
            categoryOptions={categoryOptions}
            subCategoryOptions={subCategoryOptions}
            brandOptions={brandOptions}
            shippingOptions={shippingOptions}
            discountTypeOptions={discountTypeOptions}
            weightUnitOptions={weightUnitOptions}
            genderOptions={genderOptions}
            isProductInfoEmpty={isProductInfoEmpty}
            isProductVariantEmpty={isProductVariantEmpty}
            isProductCombinationEmpty={isProductCombinationEmpty}
            isProductCombinationDiscountInvalid={isProductCombinationDiscountInvalid}
            handleRemoveImage={handleRemoveImage}
            handleFileInput={handleImagesAttachment}
            handleChangeImage={handleImagesAttachment}
            handleFileOnDrop={handleImagesOnDrop}
            handleMultiSelect={handleProductShippingList}
            setOpenFilePreview={setOpenFilePreview}
            openFormProductVariant={openFormProductVariant}
            setOpenFormProductVariant={setOpenFormProductVariant}
            openDisplaySizeGuide={openDisplaySizeGuide}
            setOpenDisplaySizeGuide={setOpenDisplaySizeGuide}
            openFormProductCombination={openFormProductCombination}
            setOpenFormProductCombination={setOpenFormProductCombination}
            setInputVariant={setInputVariant}
            inputVariant={inputVariant}
            handleAddProductInformation={handleAddProductInformation}
            handleRemoveProductInformation={handleRemoveProductInformation}
            handleProductInfoTitle={handleProductInfoTitle}
            handleProductInfoDesc={handleProductInfoDesc}
            handleInputVariantType={handleInputVariantType}
            handleAddVariantOptions={handleAddVariantOptions}
            handleRemoveInputVariantType={handleRemoveInputVariantType}
            handleRemoveVariantOptions={handleRemoveVariantOptions}
            handlePriceVariantOptions={handlePriceVariantOptions}
            handleTextVariantOptions={handleTextVariantOptions}
            handleImageVariantOptions={handleImageVariantOptions}
            handleRemoveImageVariantOptions={handleRemoveImageVariantOptions}
            handleAddProductCombination={handleAddProductCombination}
            handleRemoveProductCombination={handleRemoveProductCombination}
            handleAddCombinationList={handleAddCombinationList}
            handleRemoveCombinationList={handleRemoveCombinationList}
            handleVariantTypeProductCombinations={handleVariantTypeProductCombinations}
            handleVariantOptionProductCombinations={handleVariantOptionProductCombinations}
            handleStockProductCombinations={handleStockProductCombinations}
            handleWeightProductCombinations={handleWeightProductCombinations}
            handleWeightUnitProductCombinations={handleWeightUnitProductCombinations}
            handleDiscountTypeProductCombinations={handleDiscountTypeProductCombinations}
            handleDiscountValueProductCombinations={handleDiscountValueProductCombinations}
            handleRemoveImageProductCombinations={handleRemoveImageProductCombinations}
            handleImageProductCombinations={handleImageProductCombinations}
            handleCropImage={handleCropImage}
          />
        }
        footerContent={
          <ActionButtonFormFirst
            isDraft={true}
            isLoadingDraft={isLoadingDraft}
            isLoadingPublish={isLoadingPublish}
            disabled={formik.isSubmitting}
            onCancel={() => navigate('/products/list')}
            onDraft={() => handlePublish(formik.values, true)}
            onPublish={() => formik.handleSubmit()}
          />
        }
      />
    </>
  )
}

export default AddProduct
