import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux"

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CFormCheck,
  CImage
} from '@coreui/react-pro'
import Select from 'react-select'
import FormTextInput from '../general-input-form/FormTextInput'
import FormMultiSelectInput from '../general-input-form/FormMultiSelectInput'
import AppTextColumnDetails from 'src/components/details/general-details/AppTextColumnDetails'

// helpers
import { couponTypeText } from 'src/helpers/text-format'
import {
  singleOptionsProductCombinationSelect,
  singleOptionsSelectNew
} from 'src/helpers/format-options'
import Axios from 'src/helpers/axios'

const AppSecondForm = ({
  editPage,
  values,
  handleChange,
  handleMultiSelect,
  handleApplyCouponTo,
  errors,
}) => {
  const currency = useSelector((state) => state.currency)

  const [productListOption, setProductListOption] = useState([]);
  const [productCombinationListOption, setProductCombinationListOption] = useState([]);
  const [categoryListOption, setCategoryListOption] = useState([]);
  const [subCategoryListOption, setSubCategoryListOption] = useState([]);
  const [brandListOption, setBrandListOption] = useState([]);

  useEffect(() => {
    if (values.apply_coupon_to == "selected-products") {
      fetchAllProducts(values?.coupon_list_products)
    }
  }, [values]);

  useEffect(() => {
    if (values.apply_coupon_to == "selected-categories") {
      fetchAllCategories()
    }
    if (values.apply_coupon_to == "selected-subcategories") {
      fetchAllSubCategories()
    }
    if (values.apply_coupon_to == "selected-brands") {
      fetchAllBrands()
    }
  }, [values.apply_coupon_to]);

  const fetchAllProducts = (coupon_list_products) => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/products`,
    })
      .then(({ data }) => {
        setProductListOption(data)
        const productIds = coupon_list_products?.map(product => product?.value)

        let combinations = data?.filter(item => productIds?.includes(item?.id))?.map((item) => {
          if (item?.combinations && item?.combinations?.length > 0) {
            item?.combinations?.map((combination) => {
              combination.main_product_id = item?.id
              combination.main_product_name = item?.name
              return combination
            })
            return item?.combinations
          }
        }).filter(item => item !== undefined)

        const newArrayCombinations = Array.prototype.concat.apply([], combinations);
        setProductCombinationListOption(newArrayCombinations)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllCategories = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/categories/all`,
    })
      .then(({ data }) => {
        setCategoryListOption(data)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllSubCategories = () => {
    Axios({
      method: "get",
      url: `/api/ecommerce/v1/categories/sub/all`,
    })
      .then(({ data }) => {
        setSubCategoryListOption(data)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetchAllBrands = (queries) => {
    Axios({
      method: "get",
      url: '/api/ecommerce/v1/brands/products/all'
    })
      .then(({ data }) => {
        setBrandListOption(data)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Coupon Type"
          subtitle={values.coupon_type && values.coupon_type !== '' ? couponTypeText(values.coupon_type) : ''}
        />
        {/* <FormTextInput
          id='user_per_coupon'
          name='uses_per_coupon'
          colSize='3'
          inputLabel='Uses Per Coupon'
          placeholder='Enter uses per coupon...'
          className='mb-3'
          value={values.uses_per_coupon}
          handleTextInput={handleChange}
          feedbackInvalid={errors.uses_per_coupon}
          invalid={errors.uses_per_coupon}
        /> */}
      </CRow>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Coupon Value"
          subtitle={
            values.coupon_type === 'fixed-amount' ? `${currency?.code} ${values.coupon_value}`
              : values.coupon_type === 'percentage' ? ` ${values.coupon_value} %`
                : values.coupon_type === 'free-shipping' ? values.coupon_value && Array.isArray(values.coupon_value) && values.coupon_value.map(item => item.label).join(', ')
                  : values.coupon_type === 'voucher' ? (values.coupon_voucher_value && values.coupon_voucher_value !== '' ? `${currency?.code} ${values.coupon_voucher_value}` : ` ${values.coupon_voucher_percentage} %`)
                    : ''
          }
        />
        {/* <FormTextInput
          id='user_per_customer'
          name='uses_per_customer'
          colSize='3'
          inputLabel='Uses Per Customer'
          placeholder='Enter uses per customer...'
          className='mb-3'
          value={values.uses_per_customer}
          handleTextInput={handleChange}
          feedbackInvalid={errors.uses_per_customer}
          invalid={errors.uses_per_customer}
        /> */}
      </CRow>
      <CRow>
        <CCol className='mb-3' lg='3'>
          <CFormLabel>
            Apply Coupon To:
          </CFormLabel>
          {errors?.apply_coupon_to && (
            <div className='text-danger' style={{ fontSize: ".875em" }}>{errors?.apply_coupon_to}</div>
          )}
          <CFormCheck
            name="entire-product"
            type="radio"
            className='mt-2'
            label="Entire Product"
            checked={values.apply_coupon_to == "entire-product"}
            onChange={(e) => handleApplyCouponTo(e.target.name)}
          />
          <CFormCheck
            name="selected-products"
            type="radio"
            className='mt-2'
            label="Selected Products"
            checked={values.apply_coupon_to == "selected-products"}
            onChange={(e) => handleApplyCouponTo(e.target.name)}
          />
          <CFormCheck
            name="selected-categories"
            type="radio"
            className='mt-2'
            label="Selected Categories"
            checked={values.apply_coupon_to == "selected-categories"}
            onChange={(e) => handleApplyCouponTo(e.target.name)}
          />
          <CFormCheck
            name="selected-subcategories"
            type="radio"
            className='mt-2'
            label="Selected Subcategories"
            checked={values.apply_coupon_to == "selected-subcategories"}
            onChange={(e) => handleApplyCouponTo(e.target.name)}
          />
          <CFormCheck
            name="selected-brands"
            type="radio"
            className='mt-2'
            label="Selected Brands"
            checked={values.apply_coupon_to == "selected-brands"}
            onChange={(e) => handleApplyCouponTo(e.target.name)}
          />
        </CCol>
        {values.apply_coupon_to && values.apply_coupon_to !== 'entire-product' && (
          <CCol lg='9'>
            {/* <FormMultiSelectInput
              id='coupon_products'
              name='coupon_products'
              inputLabel={
                values.apply_coupon_to == "selected-products" ? "Select Products"
                  : values.apply_coupon_to == "selected-categories" ? "Select Categories"
                    : values.apply_coupon_to == "selected-subcategories" ? "Select Subcategories"
                      : values.apply_coupon_to == "selected-brands" ? "Select Brands"
                        : ''
              }
              placeholder={`Please select ${values.apply_coupon_to == "selected-products" ? "product"
                : values.apply_coupon_to == "selected-categories" ? "category"
                  : values.apply_coupon_to == "selected-subcategories" ? "subcategory"
                    : values.apply_coupon_to == "selected-brands" ? "brand"
                      : ''} for this coupon...`}
              colSize='12'
              className='mb-3'
              selectionOptions={
                values.apply_coupon_to == "selected-products" ? optionsMultiSelect(productListOption)
                  : values.apply_coupon_to == "selected-categories" ? optionsMultiSelect(categoryListOption)
                    : values.apply_coupon_to == "selected-subcategories" ? optionsMultiSelect(subCategoryListOption)
                      : values.apply_coupon_to == "selected-brands" ? optionsMultiSelect(brandListOption)
                        : ''
              }
              optionsTemplate={
                (option) => (
                  <div className="d-flex">
                    {option.image_url ? (
                      <>
                        <CImage className="me-3" fluid thumbnail rounded src={option.image_url} width={50} /> {option.text}
                      </>
                    ) : (
                      <>
                        {option.text}
                      </>
                    )}
                  </div>
                )
              }
              value={values.coupon_products}
              handleMultiSelect={handleMultiSelect}
              feedbackInvalid={errors.coupon_products}
              invalid={errors.coupon_products}
            /> */}
            <CFormLabel>
              {
                values.apply_coupon_to == "selected-products" ? "Select Products"
                  : values.apply_coupon_to == "selected-categories" ? "Select Categories"
                    : values.apply_coupon_to == "selected-subcategories" ? "Select Subcategories"
                      : values.apply_coupon_to == "selected-brands" ? "Select Brands"
                        : ''
              }
            </CFormLabel>
            <Select
              isMulti
              placeholder={`Please select ${values.apply_coupon_to == "selected-products" ? "product"
                : values.apply_coupon_to == "selected-categories" ? "category"
                  : values.apply_coupon_to == "selected-subcategories" ? "subcategory"
                    : values.apply_coupon_to == "selected-brands" ? "brand"
                      : ''} for this coupon...`}
              options={
                values.apply_coupon_to == "selected-products" ? singleOptionsSelectNew(productListOption)
                  : values.apply_coupon_to == "selected-categories" ? singleOptionsSelectNew(categoryListOption)
                    : values.apply_coupon_to == "selected-subcategories" ? singleOptionsSelectNew(subCategoryListOption)
                      : values.apply_coupon_to == "selected-brands" ? singleOptionsSelectNew(brandListOption)
                        : ''
              }
              value={
                values.apply_coupon_to == "selected-products" ? values.coupon_list_products
                  : values.apply_coupon_to == "selected-categories" ? values.coupon_list_categories
                    : values.apply_coupon_to == "selected-subcategories" ? values.coupon_list_subcategories
                      : values.apply_coupon_to == "selected-brands" ? values.coupon_list_brands
                        : ''
              }
              onChange={(value) => handleMultiSelect(value, values.apply_coupon_to)}
            />
            {values.apply_coupon_to == "selected-products" && (
              <div className='mt-3'>
                <CFormLabel>
                  or Select Product Combinations
                </CFormLabel>
                <Select
                  isMulti
                  placeholder={`Please select product combination for this coupon...`}
                  options={singleOptionsProductCombinationSelect(productCombinationListOption)}
                  value={values.coupon_list_product_combinations}
                  onChange={(value) => handleMultiSelect(value, "selected-products-combination")}
                />
              </div>
            )}
            {/* {invalid && (
              <CFormText className='error-text-field'>
                {feedbackInvalid}
              </CFormText>
            )} */}
          </CCol>
        )}
      </CRow>
    </>
  )
}

export default AppSecondForm
