
import React from 'react'
import moment from 'moment'
import { useSelector } from "react-redux"

// components
import {
  CRow,
  CBadge,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CButton
} from '@coreui/react-pro'
import AppTextColumnDetails from '../general-details/AppTextColumnDetails'

// helpers
import { orderStatus } from 'src/helpers/status-badge'
import { getFullName } from 'src/helpers/text-format'
import { formatPriceNumber } from 'src/helpers/format-data'

const AppFirstDetails = ({
  orderDetails,
  shippingAddressDetails,
  setShowConfirmOrderStatus
}) => {
  const currency = useSelector((state) => state.currency)

  return (
    <>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Order Status"
          subtitle={
            orderDetails?.payment_status === "pending" || orderDetails?.payment_status === "failed" ? (
              <CButton
                disabled
                color={orderStatus(orderDetails?.payment_status)}
              >
                {orderDetails?.payment_status?.toString()?.toUpperCase()}
              </CButton>
            ) : (
              <CDropdown variant="btn-group">
                <CDropdownToggle
                  color={orderStatus(orderDetails?.payment_status)}
                >
                  {orderDetails?.shipping_number && orderDetails?.shipping_number !== '' ? "SHIPPED" : orderDetails?.payment_status?.toString().toUpperCase()}
                </CDropdownToggle>
                <CDropdownMenu>
                  <CDropdownItem
                    onClick={() => setShowConfirmOrderStatus({ show: true, status: 'shipped' })}
                    disabled={orderDetails?.payment_status?.toString()?.toUpperCase() !== "SETTLEMENT" && orderDetails?.payment_status?.toString()?.toUpperCase() !== "CAPTURE" && orderDetails?.payment_status?.toString()?.toUpperCase() !== "SUCCESS"}
                  >
                    Shipped
                  </CDropdownItem>
                </CDropdownMenu>
              </CDropdown>
            )}
        />
        {orderDetails?.shipping_number && orderDetails?.shipping_number !== '' && (
          <AppTextColumnDetails
            colSize="3"
            className="mb-3"
            title="Shipping Number"
            subtitle={orderDetails?.shipping_number}
          />
        )}
      </CRow>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Purchase ID"
          subtitle={orderDetails?.transaction_id}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Purchase Date / Time"
          subtitle={moment(orderDetails?.transaction_date).format('DD/MM/YYYY HH:mm')}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Discount / Promo Used"
          subtitle={orderDetails?.promotions || '-'}
        />
      </CRow>
      <CRow>
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Customer Status"
          subtitle={orderDetails?.guest_token ? 'GUEST' : 'MEMBER'}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Customer Name"
          // subtitle={getFullName(orderDetails?.user?.profile)}
          subtitle={`${shippingAddressDetails?.first_name || shippingAddressDetails?.contact_information_first_name} ${shippingAddressDetails?.last_name || shippingAddressDetails?.contact_information_last_name}`}
        />
        <AppTextColumnDetails
          colSize="3"
          className="mb-3"
          title="Amount Purchase"
          subtitle={formatPriceNumber(orderDetails?.transaction_amount, currency?.code)}
        />
      </CRow>
    </>
  )
}

export default AppFirstDetails
