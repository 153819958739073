import React, { useEffect, useState } from 'react'

// components
import {
  CCol,
  CRow,
  CFormLabel,
  CAccordion,
  CAccordionHeader,
  CAccordionBody,
  CAccordionItem,
  CButton,
  CImage,
  CFormText
} from '@coreui/react-pro'
import FormProductCombination from '../general-input-form/FormProductCombination';

// icons
import AddMoreIconWhite from '../../../assets/icons/AddMoreIconWhite.png'
import TrashIcon from '../../../assets/icons/TrashIcon.png'

// styling
import { styleButtonVars3 } from 'src/components/custom-styles/buttonStyles';

const AppThirdForm = ({
  editPage,
  values,
  errors,
  isProductCombinationEmpty,
  isProductCombinationDiscountInvalid,
  discountTypeOptions,
  weightUnitOptions,
  handleAddCombinationList,
  handleRemoveCombinationList,
  handleAddProductCombination,
  handleRemoveProductCombination,
  handleVariantTypeProductCombinations,
  handleVariantOptionProductCombinations,
  handlePriceProductCombinations,
  handleStockProductCombinations,
  handleWeightProductCombinations,
  handleWeightUnitProductCombinations,
  handleDiscountTypeProductCombinations,
  handleDiscountValueProductCombinations,
  handleRemoveImageProductCombinations,
  handleImageProductCombinations,
  setOpenFilePreview,
  setShowConfirmDelete
}) => {
  const [isProductVariantError, setProductVariantError] = useState(false)
  const disableAdd = values.product_variants.every(variant => variant.options.every(option => !option?.option_name || !option?.option_price))

  const handleAdd = () => {
    if (disableAdd) {
      setProductVariantError(true)
    } else {
      setProductVariantError(false)
      handleAddProductCombination()
    }
  }

  useEffect(() => {
    if (!disableAdd) {
      setProductVariantError(false)
    }
  }, [disableAdd])

  return (
    <>
      <CRow>
        <CCol lg={12} >
          <div className='mb-3 d-grid gap-2 d-md-flex justify-content-between align-items-center'>
            <CFormLabel>
              Product Combination
            </CFormLabel>
            <CButton
              onClick={handleAdd}
              style={styleButtonVars3}
            >
              <CImage fluid src={AddMoreIconWhite} className='me-2' />
              {values.product_combinations.length > 0 ? 'Add More' : 'Add'}
            </CButton>
          </div>
        </CCol>
        {isProductVariantError && (
          <CFormText className='mb-3 w-100 error-text-field'>
            Before you add a product combination, make sure you've filled in all the fields for product variant options like name and price.
          </CFormText>
        )}
        {isProductCombinationEmpty && (
          <CFormText className='mb-3 error-text-field'>
            Please fill out all of the fields for variant combinations, quantity and weight.
          </CFormText>
        )}
      </CRow>
      <CRow className="mb-4">
        <CCol lg='12'>
          {values.product_combinations.length > 0 && (
            <CAccordion>
              {values.product_combinations.map((combination, index) => {
                return (
                  <CAccordionItem itemKey={index} key={index} >
                    <CAccordionHeader>
                      <b>{combination.name}</b>
                      <CButton
                        className='ms-3'
                        style={styleButtonVars3}
                        onClick={() => {
                          combination.combination_id ?
                            setShowConfirmDelete({ show: true, item: combination, type: 'product-combination' })
                            : handleRemoveProductCombination(index)
                        }}
                      >
                        <CImage fluid src={TrashIcon} className='me-2 mb-1' />
                        Remove
                      </CButton>
                    </CAccordionHeader>
                    <CAccordionBody>
                      <FormProductCombination
                        formProduct
                        editPage={editPage}
                        discountTypeOptions={discountTypeOptions}
                        weightUnitOptions={weightUnitOptions}
                        variantList={values.product_variants}
                        combinationDetail={combination}
                        combinationIndex={index}
                        handleAddCombinationList={handleAddCombinationList}
                        handleRemoveCombinationList={handleRemoveCombinationList}
                        handleVariantTypeProductCombinations={handleVariantTypeProductCombinations}
                        handleVariantOptionProductCombinations={handleVariantOptionProductCombinations}
                        handlePriceProductCombinations={handlePriceProductCombinations}
                        handleWeightProductCombinations={handleWeightProductCombinations}
                        handleWeightUnitProductCombinations={handleWeightUnitProductCombinations}
                        handleDiscountTypeProductCombinations={handleDiscountTypeProductCombinations}
                        handleDiscountValueProductCombinations={handleDiscountValueProductCombinations}
                        handleStockProductCombinations={handleStockProductCombinations}
                        handleRemoveImageProductCombinations={handleRemoveImageProductCombinations}
                        handleImageProductCombinations={handleImageProductCombinations}
                        setOpenFilePreview={setOpenFilePreview}
                        isProductCombinationDiscountInvalid={isProductCombinationDiscountInvalid}
                      />
                    </CAccordionBody>
                  </CAccordionItem>
                )
              })}
            </CAccordion>
          )}
        </CCol>
      </CRow>
    </>
  )
}

export default AppThirdForm
